import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();

    //Autenticação utilizando a própria API
    const login = (user, password) => {
        api
            .post('/autenticacao', {
                user: user,
                pwd: password})
            .then(response => {
                sessionStorage.setItem('token', response.data.key);
                navigate('/home');
            })
            .catch(error => {
                alert("Incorrect login");
                console.log(error);
            });
    }

    const logout = () => {
        sessionStorage.removeItem('token');
        navigate('/login');
    }

    return (
        <AuthContext.Provider value={{login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth(){
    const {login, logout} = useContext(AuthContext);

    return { login, logout };
}