import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext/index';

import Documentation from './pages/DocumentationPage/index.jsx';
import LoginPage from './pages/LoginPage/index';
import HomePage from './pages/MainPage';
import ProfilePage from './pages/ProfilePage/index';

function AppRoutes() {
    return(
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/documentation" element={<Documentation />} />
                </Routes>
            </AuthProvider>
        </Router>
    )
}

export default AppRoutes;