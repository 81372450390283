import { Navigate } from "react-router-dom";

import React from "react";


const documentationUrl = "https://dkz4b5wxmrebg.cloudfront.net"
export default function LoginPage() {
    if(sessionStorage.getItem('token')){
        return(
            <div id="doc" style={{position: "fixed", padding: "0", margin: "0", height: "100%", width: "100%"}}>
                <iframe id="documentation" height="100%" width="100%" src={documentationUrl} title="" allowFullScreen>
                </iframe>
            </div>
        );
    }
    else{
        return(
            <Navigate to="/login"/>
        );
    }
}