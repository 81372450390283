import "assets/scss/material-kit-react.scss?v=1.9.0";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";

import AppRoutes from "routes";

///Versão 1.3.6

ReactDOM.render(
  <AppRoutes/>,
  document.getElementById("root")
);
