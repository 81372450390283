import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Image } from "@material-ui/icons";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

import { container } from "assets/jss/material-kit-react";
import { Link } from "react-router-dom";
import imperador from '../../../assets/img/Imperador_4000_capa_01-1.png';
import zeroAmassamento from '../../../assets/img/Tecnologia-Stara-1.jpeg';

const useStyles = makeStyles(styles);

export default function SectionBasics() {
  const classes = useStyles();

  return (
    <div className={classes.sections}>
      <div className={classes.container} id="container">
        <h1 id="stara-telemetry-api">Stara Telemetry API</h1>
        <p>Welcome to the Stara Developers website. Here you can find the most important information and tips to start using the Stara Telemetry API.</p>
        
        <h2 id="summary">Summary</h2>
        <ul>
        <li><a href="#overview">Overview</a></li>
        <li><a href="#available-information">Available information</a></li>
        <li><a href="#functionalities">Functionalities</a></li>
        <li><a href="#getting-started">Getting started</a></li>
        <li><a href="#questions">Questions</a></li>
        </ul>
        
        <h2 id="overview">Overview</h2>
        <p>The Stara Telemetry API is a software that links the Stara Telemetry customers information to your company. This is all done through a REST API, that searches the newest information according to your needs.</p>
        <p><img src={imperador}/></p>
        
        <h2 id="available-information">Available information</h2>
        <p>All the data available in the Stara Telemetry website are available in our API as well. Using our system you can search for:</p>
        <ul>
          <li>Machine information</li>
          <li>Job information</li>
          <li>Application maps</li>
        </ul>
        
        <h2 id="functionalities">Functionalities</h2>
        <p>There are no limits to the use the data obtained through the Stara Telemetry API. Using the system you can, not only use the information requested to  the server, but cross it with information from another request, or even info on your system . For example:</p>
        <ul>
          <li>Machine average speed during a job.</li>
          <li>Applied area vs. overlapped area.</li>
          <li>Operational efficiency + stopping reasons.</li>
        </ul>
        <p><img src={zeroAmassamento} alt="zero_amassamento" width={container.width}/></p>
        
        <h2 id="getting-started">Getting started</h2>
        <p>When accessing this website, you already have a Stara Telemetry account. Your first step to integrate is obtaining your <Link to={'/profile'}>API key</Link>. You can do this by accessing the Options menu on the top of the page. Go for Options &gt; API key.</p>
        <p>Having this key, you can make a request for obtaining the session access tokens. For more details on how to integrate, access the <Link to={'/documentation'}>full documentation</Link> at Options &gt; API documentation.</p>
        
        <h2 id="questions">Questions</h2>
        <p>It is natural that the first contact with the system may result in some doubts. In this case, if you want to know more details about the system, new endpoints and information available on the API, access the <Link to={'/documentation'}>documentation</Link> above mentioned.</p>
        <p>In case you want to know more Stara Telemetry solutions, please contact our <a href="mailto:servicos@stara.com.br">Services Department</a>.</p>
      </div>
    </div>
    
  );
}