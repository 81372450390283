import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';



// core components
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import email from "../../assets/img/icon/email.png";


import styles from "assets/jss/material-kit-react/views/profilePage.js";


const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  /* const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  ); */
 

  //Botão para copiar a Key
const copy = () => {
  const el = document.createElement('textarea');
  el.value = sessionStorage.getItem('token');
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
  //Verifica se o usuário está logado
  if(sessionStorage.getItem('token')){
    return (
      <div>
        <Header
          color="transparent"
          brand=""
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/Stara_Api_2.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div>
                      {/* <img src={profile} alt="..." className={imageClasses} /> */}
                    </div>
                    {/* <Button color="success" size="sm" simple>
                      Change the Password
                    </Button> */}
  
                    <div className={classes.name}>
                      <br />
                      <br />
                      <h3 className={classes.title}>Your key</h3>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}></div>
              <CardHeader color="dark" className={classes.cardHeader}>
                {sessionStorage.getItem("token")}
              </CardHeader>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <Button onClick={copy} color="dark" className={classes.cardHeader}>Copy Key</Button>
                <br/>
                <br/>
                  <p>
                  This token is unique and non-transferable. It is what guarantees your access to Stara Telemetry API, 
                  and is required to authenticate the customer information.
                    <br/>For further information, doubts and technical support, contact the Services Center:<br/>
                    <i><img className={classes.icons} src={email} alt=""></img> - Email: servicos@stara.com.br</i>
                  </p>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }else{
    return (
      <Navigate to="/login" />
    );
  }
}
