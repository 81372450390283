import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link, Navigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";

import { AuthContext } from "../../contexts/AuthContext/index";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
/* import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionLogin from "./Sections/SectionLogin.js";
import img from '../../assets/img/logo-pt.png'; */
import styles from "assets/jss/material-kit-react/views/components.js";
import logo_stara from '../../assets/img/logo_stara.png';

const useStyles = makeStyles(styles);


export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  //Verifica se o usuário está logado
  if(sessionStorage.getItem('token')){
    return (
      <div>
        <Header
          brand={""}
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/Stara_Api_2.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                  
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <GridContainer>
            <GridItem>
              <div className={classes.img}>
                <img src={logo_stara}  alt=""></img>
              </div>
            </GridItem>
          </GridContainer>
        </Parallax>
        <div>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <SectionBasics />
          </div>
        </div>
        <Footer />
      </div>
    );
  }else{
    return(
      <Navigate to="/login" />
    );
  }
}
