/*eslint-disable*/
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { AuthContext } from "../../contexts/AuthContext/index";

const useStyles = makeStyles(styles);


export default function HeaderLinks(props) {
  const classes = useStyles();

  const { token, logout } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    logout();
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Options"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/home" className={classes.dropdownLink}>
             Home
            </Link>,
             <Link to="/profile" className={classes.dropdownLink}>
             API Key
            </Link>,
            <Link to="/documentation" className={classes.dropdownLink}>
              API Documentation
            </Link>,
            <Link className={classes.dropdownLink} onClick={handleSubmit} to="/login">
              Logout
            </Link>
          ]}
        />
      </ListItem>
    </List>
  );
}
